import { Route, Routes } from 'react-router-dom';
import './App.css';
import { useState, useEffect, useRef } from 'react';
import Appointment from './Components/Appointment';
import Success from './Components/Success';
import Signup from './Components/Signup';
import Locations from './Components/Locations';
import Loyalty from './Components/Loyalty';
import Feedback from './Components/Feedback';
import Referral from './Components/Referral';


function App() {
  // const [scrollHeight, setScrollHeight] = useState(0);
  // const [backgroundColor, setBackgroundColor] = useState('#3f2caa');
  // const [navColor, setNavColor] = useState('#16181c');
  // const scrollableElementRef = useRef(null);

  // useEffect(() => {
  //   function handleScroll() {
  //     if (scrollableElementRef.current) {
  //       const currentScrollHeight = scrollableElementRef.current.scrollTop;
  //       setScrollHeight(currentScrollHeight);
  //     }
  //   }

  //   if (scrollableElementRef.current) {
  //     scrollableElementRef.current.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     if (scrollableElementRef.current) {
  //       scrollableElementRef.current.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, []);

  // const getBackgroundColor = () => {
  //   if (scrollHeight > 5929) {
  //     return '#16181c';
  //   } else if (scrollHeight > 4579) {
  //     return '#23252c';
  //   } else if (scrollHeight > 2600) {
  //     return '#16181c';
  //   } else if (scrollHeight > 1760) {
  //     return '#01b5ac';
  //   } else if (scrollHeight > 0) {
  //     return '#3f2caa';
  //   } else {
  //     return 'white';
  //   }
  // };


  return (
    <div className="w-screen h-screen">
      <Routes>
        <Route path="/book-appointment/:id" element={<Appointment />} />
        <Route path="/select-appointment-location/:id" element={<Locations />} />

        <Route path="/loyalty/:contactId/:companyId" element={<Loyalty />} />
        <Route path="/feedback/:contactId/:companyId" element={<Feedback />} />
        <Route path="/referral/:contactId/:companyId" element={<Referral />} />    

        {/* <Route path="/signup" element={<Signup />} /> */}

        <Route path="/success" element={<Success />} /> 
      </Routes>
    </div>
  );
}

export default App;
