import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import axios from 'axios';
import LoyaltyPreview from './LoyaltyPreview';
import cafelogo from '../Assets/cafelogo.jpeg';
import medal from '../Assets/medal.png';
import { GoStar } from 'react-icons/go';
import { FaChevronDown } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";
import SimpleModal from './SimpleModal';
import SimpleModalTwo from './SimpleModalTwo';
import FeedbackComponent from './FeedbackComponent';
import LoyaltyFooter from './LoyaltyFooter';
import { useNavigate, useParams } from 'react-router-dom';
import { baseURL } from '../Headers';


function Loyalty() {
  const {contactId,companyId} = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // console.log('>>',contactId,companyId);
  

  const [themeColor, setThemeColor] = useState('#1c4c3c');
  const [spendPoints, setSpendPoints] = useState(1.0);
  const [referal, setreferal] = useState(100);
  const [feedback, setfeedback] = useState(100);
  const [review, setreview] = useState(100);
  const [information, setInformation] = useState(100);
  const [points, setPoints] = useState('');
  const [minAmount, setMinAmount] = useState('');
  const [pointdesc, setPointdesc] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('Cafe Mysa');
  const [loyaltyLogo, setLoyaltyLogo] = useState('');
  const [coverImage, setcoverImage] = useState('');
  const [openModal, setOpenModal] = useState(true);
  const [latestPurchase, setLatestPurchase] = useState(null);
  const [showDetails, setshowDetails] = useState(false)
  // const [sections, setSections] = useState([{ id: 1, minSpendSwitch: false }]);
  const [sections, setSections] = useState([
    {
      id: 1, points: '1000', pointdesc: '75₹ off on entire purchase', minAmount: '399', minSpendSwitch: false, 
    },
  ]);

  const [pointsToNextReward, setPointsToNextReward] = useState(null); // Points needed to unlock next reward
  const [unlockedRewards, setUnlockedRewards] = useState(0); // Number of unlocked rewards
  const [googleLink, setgoogleLink] = useState('');

  useEffect(() => {
    handleGetLoyaltySettings();
  }, []);


  useEffect(() => {
      const updateRewardsStatus = () => {
        let unlockedCount = 0;
        let nextRewardPoints = null;  // Points remaining for next reward in INR
    
        const updatedSections = sections.map((section) => {
          const requiredPoints = section.points; // Points needed for the current reward
          const isUnlocked = points >= requiredPoints;
    
          if (isUnlocked) {
            unlockedCount++;
          } else if (!nextRewardPoints) {
            // Calculate how many points are still needed to unlock the next reward
            const pointsNeeded = requiredPoints - points;
    
            // Convert pointsNeeded to INR based on spendPoints factor
            nextRewardPoints = Math.ceil(pointsNeeded / spendPoints);         }
    
          return { ...section, locked: !isUnlocked };
        });
    
        // Update sections and unlocked rewards count if necessary
        if (JSON.stringify(sections) !== JSON.stringify(updatedSections)) {
          setSections(updatedSections);
        }
    
        if (unlockedCount !== unlockedRewards) {
          setUnlockedRewards(unlockedCount);
        }
    
        if (nextRewardPoints !== pointsToNextReward) {
          setPointsToNextReward(nextRewardPoints);
        }
      };
    
      updateRewardsStatus();
  }, [points, sections, unlockedRewards, pointsToNextReward, spendPoints]);
    
  
  

  const handleGetLoyaltySettings = async () => {
    try {
      setLoading(true);  
    
      const res = await axios.get(`${baseURL}/loyalty/get-loyalty-points-for-cx/${contactId}/${companyId}`);
      console.log('getLoyaltySettings res', res);

      if (res?.data?.status === 'success') {
        setThemeColor(res.data?.data?.loyaltySettings?.themeColor || themeColor);
        setTitle(res.data?.data?.loyaltySettings?.title)
        setName(res.data?.data?.loyalty?.contactName);
        setPoints(res.data?.data?.loyalty?.points);
        setgoogleLink(res.data?.data?.googleReviewLink);
        const converters = res.data?.data?.loyaltySettings?.converters || [];
        setSpendPoints(converters.find((item) => item.action === 'purchase')?.pointsPerUnit || 1.0);
        setreferal(converters.find((item) => item.action === 'refer')?.pointsPerUnit || 100);
        setfeedback(converters.find((item) => item.action === 'feedback')?.pointsPerUnit || 100);
        setreview(converters.find((item) => item.action === 'review')?.pointsPerUnit || 100);
        setInformation(converters.find((item) => item.action === 'information')?.pointsPerUnit || 100);        

        setLoyaltyLogo(res.data?.data?.loyaltySettings?.loyaltyLogo || '');
        setcoverImage(res.data?.data?.loyaltySettings?.coverImage || '');

        const rewards = res.data?.data?.loyaltySettings?.rewards || [];
        setSections(rewards.map((section) => ({
          id: section._id,
          points: Number(section.points) || 0,
          pointdesc: section.description || '',
          minAmount: Number(section.minSpendRequired) || 0,
          minSpendSwitch: section.minSpendSwitch || false,
        })));

        const transactions = res.data?.data?.loyalty?.transactions || [];
        const latestPurchaseAction = transactions
          .filter(transaction => transaction.action === 'purchase')
          .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Sort by date and get the most recent one

        setLatestPurchase(latestPurchaseAction || null); // Set the latest purchase action

        setLoading(false);
      } else {
        toast.error(res.data?.message || 'Failed to fetch settings');
      }
    } catch (err) {
      console.error('Error fetching loyalty settings:', err);
      toast.error(err.message);
    }
  };

  const handleFeedback = () => {
    navigate(`/feedback/${contactId}/${companyId}`, {
        state: {
            feedback,
            themeColor,
            googleLink,
            review,
            loyaltyLogo,
            title,            
        }
    });
};

const handleReferral = () => {
    navigate(`/referral/${contactId}/${companyId}`, {
        state: {
            themeColor,
            loyaltyLogo,
            title,
         
            referal
            
        }
    });
};

  // console.log('sections',sections,pointsToNextReward,unlockedRewards ,latestPurchase);
  
if(loading){
  return <div className='w-screen h-screen overflow-x-hidden flex items-center justify-center'><span class="loader"></span></div>
}

 return(
<div style={{ backgroundColor: themeColor }} className="w-screen h-screen overflow-x-hidden">
  <div  className="h-[104px] flex justify-center items-center">
    <div className="w-[331px] sm:w-[358px] flex flex-col">
     <span className="medium text-2xl text-white">Hello {name}</span>
     <span className=" text-sm text-white">Thank you for being our valued customer. 🤍</span>
    </div>
  </div>
  <div className="w-full bg-white flex justify-center pt-6 sm:pt-10 pb-20 rounded-t-2xl">
        <div className="w-[346px] sm:w-[358px] flex flex-col  gap-4">
          <div className="flex items-center gap-2">
          <div className="  bg-white rounded-full w-16 h-w-16 overflow-clip object-cover">
            <img
              src={loyaltyLogo || cafelogo}
              alt="Cafe Logo"
              className="w-full aspect-auto"
            />
          </div>
          <span className="text-base medium">{title}</span>
          </div>
                <div className="flex flex-col items-center justify-center gap-3 ">
                <span className="text-lg medium ">Your Reward Points</span>

                <div className="flex items-center gap-2">
                  
                <div className="  bg-white rounded-full w-10 overflow-clip object-cover">
                
                  <img
                    src={medal}
                    alt="Cafe Logo"
                    className="w-full aspect-square"
                  />
                </div>
                <span className="text-xl semibold">{points}</span>
                </div>
                <span className="text-xs text-[#555c67]">Your points (Expires on May 23, 2025)</span>

                </div>

          <div className="flex flex-col mt-4 gap-2 ">
                <span className="text-sm medium">Earn more</span>

                <div className=" flex items-center gap-3">
                  <div onClick={handleReferral} className=" h-16  min-w-1/2 w-1/2 grow relative cursor-pointer">
                    <div className=" w-full h-full flex items-center gap-3 sm:gap-4 cursor-pointer border-[2px] border-[#3e4553] bg-white rounded-xl absolute  overflow-clip object-cover z-10">
                    <div className="h-[35px] w-[35px] bg-[#ffe1f8] ml-2 shrink-0 flex items-center justify-center rounded-md">
                        <GoStar className="z-10" />
                        <span className="w-[10px] h-[10px] rounded-full bg-[#f67bda] absolute mt-2 ml-1" />
                     </div>
                     <div className="flex ">
                      <div className="flex flex-col gap-1">
                         <span className="text-sm medium ">Refer a friend</span> 
                         <div className=" flex  items-center">
                              
                              <img
                                src={medal}
                                alt="Cafe Logo"
                                className="w-[23px] aspect-square"
                              />
                              <span className='medium'>{referal}</span> 
                              <span className='text-xs ml-1'>pts</span> 

                          </div> 
                        </div>
                     </div>
                    </div>
                    <div style={{ backgroundColor : themeColor }} className="w-full h-full  rounded-xl border-[2px] border-[#3e4553] absolute top-2  z-[9]" />
                    </div>

                    <div onClick={handleFeedback} className=" h-16  min-w-1/2 w-1/2 grow relative cursor-pointer">
                    <div className=" w-full h-full flex items-center gap-3 sm:gap-4 cursor-pointer border-[2px] border-[#3e4553] bg-white rounded-xl absolute  overflow-clip object-cover z-10">
                    <div className="h-[35px] w-[35px] bg-[#ffe1f8] ml-2 shrink-0 flex items-center justify-center rounded-md">
                        <VscFeedback className="z-10" />
                        <span className="w-[10px] h-[10px] rounded-full bg-[#f67bda] absolute mt-2 ml-1" />
                     </div>
                     <div className="flex ">
                      <div className="flex flex-col gap-1">
                         <span className="text-sm medium ">Feedback</span> 
                         <div className=" flex  items-center">
                              
                              <img
                                src={medal}
                                alt="Cafe Logo"
                                className="w-[23px] aspect-square"
                              />
                              <span className='medium'>{feedback}</span> 
                              <span className='text-xs ml-1'>pts</span> 

                          </div> 
                        </div>
                     </div>
                    </div>
                    <div style={{ backgroundColor : themeColor }} className="w-full h-full  rounded-xl border-[2px] border-[#3e4553] absolute top-2  z-[9]" />
                    </div>
                </div>


          </div>

           <LoyaltyPreview  
              loyaltyLogo={loyaltyLogo}
              title={title}
              coverImage={coverImage}
              unlockedRewards={unlockedRewards}
              pointsToNextReward={pointsToNextReward}
              sections={sections}
              spendPoints={spendPoints}
              themeColor={themeColor}

            />


        <div className="h-24 w-[346px] sm:w-[358px] flex mt-4 p-2  bg-gray-100  rounded-lg ">
          <div className="flex flex-col gap- justify-center pl-3">
            <span className="text-sm text-black medium mb-3">How to Redeem?</span>
            <span className="text-[#555c67] text-[12px] leading-4">Give your mobile number to the staff on your next purchase and ask them to redeem.</span>
            {/* <span className="text-[#555c67] text-[10px]">*2 offers cannot be clubbed.</span> */}

          </div>
        </div>

          <LoyaltyFooter />

          {/* <SimpleModalTwo width="100vw" padding="0px"  open={openModal} setOpen={setOpenModal}>
            <FeedbackComponent themeColor={themeColor} googleLink={googleLink} feedback={feedback} review={review}  latestPurchase={latestPurchase} handleFeedback={handleFeedback}  />
          </SimpleModalTwo> */}
        </div>
    </div>
</div>
 );
}

export default Loyalty;
