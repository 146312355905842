import React, { useEffect, useState } from 'react'
import { BsChatText } from "react-icons/bs";
import { MdKeyboardBackspace } from "react-icons/md";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { GoStar } from 'react-icons/go';
import medal from '../Assets/medal.png';

import { BsFillLightningFill } from "react-icons/bs";

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoyaltyFooter from './LoyaltyFooter';
import { baseURL } from '../Headers';
import axios from 'axios';
import toast from 'react-hot-toast';

function Feedback() {
  const {contactId,companyId} = useParams();

    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState('');
    const [starRating, setStarRating] = useState('')
    const location = useLocation();
    const navigate = useNavigate();

    // const { feedback, themeColor, googleLink, review,  loyaltyLogo,
    //     title, } = location.state || {};

    const [themeColor, setThemeColor] = useState('#1c4c3c');
 
    const [feedback, setfeedback] = useState(100);
    const [review, setreview] = useState(100);
 
    const [title, setTitle] = useState('Cafe Mysa');
    const [loyaltyLogo, setLoyaltyLogo] = useState('');
    const [googleLink, setgoogleLink] = useState('');
  
    useEffect(() => {
      handleGetLoyaltySettings();
    }, []);

  
    const handleGetLoyaltySettings = async () => {
      try {
        setLoading(true);  
      
        const res = await axios.get(`${baseURL}/loyalty/get-loyalty-points-for-cx/${contactId}/${companyId}`);
        console.log('getLoyaltySettings res', res);
  
        if (res?.data?.status === 'success') {
          setThemeColor(res.data?.data?.loyaltySettings?.themeColor || themeColor);
          setTitle(res.data?.data?.loyaltySettings?.title)
          setgoogleLink(res.data?.data?.googleReviewLink);
          const converters = res.data?.data?.loyaltySettings?.converters || [];
          setfeedback(converters.find((item) => item.action === 'feedback')?.pointsPerUnit || 100);
          setreview(converters.find((item) => item.action === 'review')?.pointsPerUnit || 100);
  
          setLoyaltyLogo(res.data?.data?.loyaltySettings?.loyaltyLogo || '');
          setLoading(false);
        } else {
          toast.error(res.data?.message || 'Failed to fetch settings');
        }
      } catch (err) {
        console.error('Error fetching loyalty settings:', err);
        toast.error(err.message);
      }
    };
    console.log('location', feedback, themeColor, googleLink, review);
    

    const feedbackOptions = [
      { emoji: '😭', label: 'Sad', value:'ONE' },
      { emoji: '🙁', label: 'Meh', value:'TWO' },
      { emoji: '😐', label: 'Okay', value:'THREE' },
      { emoji: '😄', label: 'Good', value:'FOUR' },
      { emoji: '😍', label: 'Loved it!', value:'FIVE' },
    ];

    // const handleCreateInternalFeedback = async () => {
    //   const payload = {
    //     comment, starRating
    //   }
    //   try {
    //     setLoading(true);       
    //     const res = await axios.post(`${baseURL}/reviews/create-internal-feedback/${contactId}/${companyId}`,payload);  
    //     if (res?.data?.status === 'success') {
    //       setLoading(false);
    //       handleAddLoyaltyPoints();
    //     } else {
    //       toast.error(res.data?.message || 'Something went wrong');
    //     }
    //   } catch (err) {
    //     console.error('Error adding loyalty points:', err);
    //     toast.error(err.message);
    //   }
    // };

  const handleCreateInternalFeedback = async () => {
    // Check if comment and starRating are provided
    if (!comment || !starRating) {
      toast.error('Please provide both a comment and a star rating.');
      return; // Exit the function if either field is missing
    }

    const payload = {
      comment,
      starRating
    };

    try {
      setLoading(true);
      const res = await axios.post(`${baseURL}/reviews/create-internal-feedback/${contactId}/${companyId}`, payload);
      if (res?.data?.status === 'success') {
        setLoading(false);
        handleAddLoyaltyPoints();
      } else {
        toast.error(res.data?.message || 'Something went wrong');
      }
    } catch (err) {
      console.error('Error adding loyalty points:', err);
      toast.error(err.message);
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };


    const handleAddLoyaltyPoints = async () => {
      const payload = {
        action: 'feedback', amount: '0',
      }
      try {
        setLoading(true);       
        const res = await axios.post(`${baseURL}/loyalty/add-loyalty-points/${contactId}/${companyId}`,payload);  
        if (res?.data?.status === 'success') {
          setLoading(false);
          navigate(`/success`, {
            state: {
              heading: "Feedback Submitted", 
              subHeading: "Thank you for your valuable feedback; it will help us continue to improve.", 
              navigateBackPath: `/loyalty/${contactId}/${companyId}`,      
            }
          });
        } else {
          toast.error(res.data?.message || 'Something went wrong');
        }
      } catch (err) {
        console.error('Error adding loyalty points:', err);
        toast.error(err.message);
      }
    };

    const handleNavigateBack = () => {
      navigate(`/feedback/${contactId}/${companyId}`)
    }
  
    if(loading){
      return <div className='w-screen h-screen overflow-x-hidden flex items-center justify-center'><span class="loader"></span></div>
    }
    return (
        <div className='w-screen min-h-screen flex justify-center overflow-x-hidden  overflow-y-auto'>
      <div className="w-[340px] py-4 bg-white ">
        <div className="py-4 border-b mb-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
               <span className="text-sm bg-black text-white p-[6px] rounded-full"><BsChatText /></span> 
                <span className="text medium">Feedback</span>
            </div>
            <div onClick={() => navigate(-1)} className="flex items-center gap-2 cursor-pointer hover:underline">
               <span className="text-sm "><MdKeyboardBackspace /></span> 
                <span className="text-xs">Back</span>
            </div>
        </div>
        <div className="flex justify-center items-center gap-2">
          <div className="  bg-white rounded-full w-16 h-w-16 overflow-clip object-cover">
            <img
              src={loyaltyLogo || ''}
              alt="Cafe Logo"
              className="w-full aspect-auto"
            />
          </div>
          <span className="text-base medium">{title}</span>
          </div>
        <div className="text-center text-xl semibold my-4 mt-4">How are you feeling?</div>
        <div className="text-center text-xs text-gray-600 mb-6">
          Your input is valuable in helping us better understand your needs and tailor our service accordingly.
        </div>
        
        <div className="flex justify-around my-10 gap-4">
          {feedbackOptions.map((option, index) => (
            <div 
              key={index}
              onClick={() =>{ setSelected(index); setStarRating(option.value) }}
              className={`emoji-option flex flex-col items-center cursor-pointer transition-all duration-300 ease-in-out 
                ${selected === index ? ' text-black ' : 'text-[#555c67]'}`}
            >
              <span className={`text-3xl transition-all duration-300 ease-in-out  flex justify-center ${selected === index ? '  w-[50px] h-[50px] scale-125 bg-gradient-to-b from-emerald-100 to-teal-200 p-2 rounded-full text-black ' : ''}`}>{option.emoji}</span>
              <span className="text-xs medium mt-3">{option.label}</span>
            </div>
          ))}
        </div>

          <div className="bonus-points mt-4 w-full flex justify-center items-center py-2 -mb-[3px] text-xs bg-[#fcf5ff] text-[#8e44ad] p-1 px-5 rounded-t-md">
      <BsFillLightningFill className="" />
        <p>
          <span className="medium">Earn {feedback} Bonus Pts</span> on giving feedback
        </p>
      </div>

        <textarea
        value={comment}
        onChange={(e)=>setComment(e.target.value)}
          className={`mb-6 overflow-hidden focus:ring-[2px] shadow-sm
            shadow-gray-200 border block w-[100%]      
            text-gray-900 text-sm rounded-lg outline-none min-h-[80px]
            focus:border-[1px] focus:ring-blue-200 focus:border-blue-500 
            p-1.5 pl-2 placeholder:text-[12px] placeholder:tracking-wide` 
              }
          placeholder="Add your feedback ..."
        />
        
        <button
          onClick={handleCreateInternalFeedback}
           style={{ backgroundColor: themeColor }} 
           className="w-full duration-200 text-sm medium ease-in-out text-white py-2 rounded-lg hover:brightness-125 transition-colors">
          Submit Feedback
        </button>

        <div className="flex flex-col mt-6 gap-2 ">
          <span className="text-sm medium">Earn more</span>
          <a href={googleLink} target="_blank" rel="noopener noreferrer">
          <div className=" flex  gap-3">
            <div className=" h-16  min-w-1/2 w-1/2 grow relative">
              <div className=" w-full h-full flex items-center  gap-3 sm:gap-4 cursor-pointer border-[2px] border-[#3e4553] bg-white rounded-xl absolute  overflow-clip object-cover z-10">
              <div className="h-[35px] w-[35px]  ml-2 shrink-0 flex items-center justify-center rounded-md">
                  <FcGoogle  className="z-10 text-[30px]" />
                  {/* <span className="w-[10px] h-[10px] rounded-full bg-[#f67bda] absolute mt-2 ml-1" /> */}
                </div>
                <div className="flex items-center justify-between ">
                <div className="flex flex-col gap-1">
                    <span className="text-sm medium ">Review us on Google</span> 
                    <div className=" flex  items-center">
                        
                        <img
                          src={medal}
                          alt="Cafe Logo"
                          className="w-[23px] aspect-square"
                        />
                        <span className='medium'>{review}</span> 
                        <span className='text-xs ml-1'>pts</span> 

                    </div> 
                  </div>
                </div>
                <div className=' absolute right-6 text-gray-600'><FaCircleChevronRight /></div>

              </div>
              <div style={{ backgroundColor : themeColor }} className="w-full h-full  rounded-xl border-[2px] border-[#3e4553] absolute top-2  z-[9]" />
              </div>
          </div>
          </a>       
        </div>
        <div className="min-h-24 w-full flex mt-10 p-2 py-3 bg-gray-100  rounded-lg ">
            <div className="flex flex-col gap- justify-center px-3">
              <span className="text-sm text-black medium mb-3">How to Redeem Google Points?</span>
              <span className="text-[#555c67] text-[12px] leading-4">Share screenshot of the review with store on whatsapp or show it to the staff on your next visit and ask them to redeem.</span>
              {/* <span className="text-[#555c67] text-[10px]">*2 offers cannot be clubbed.</span> */}

            </div>
          </div>
            <div className="mb-10">
          <LoyaltyFooter />
          </div>
        </div>
      </div>
    );
}

export default Feedback
